<!-- ----------------------------------------------------------------------- -->
<!--
name    : TENANTS VIEW

type    : view

route   : /tenants
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="tenants-view">
    <!-- router-view will be replaced with whichever route is active -->
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'TenantsView',
    components: {},
  };
</script>
